import RcObject from "@/domain/common/RcObject.js";

export default class CardParams extends RcObject {

  static MODEL_NAME = "CardParams";

  static Fields = {
    FILTER: "filter",
  }

  constructor() {
    super({});
  }

  with(cardMap) {
    this.put(cardMap.modelName(), cardMap);
    return this;
  }
  
  withFilter(filter) {
    this.put(CardParams.Fields.FILTER, filter ? filter.data : null);
    return this;
  }
}
  