
import RcTableViewModel from "../../../../shared/table/RcTableViewModel";

import CardMap from '@/domain/model/invoice/CardMap.js';

import CardListFilter from "@/domain/model/invoice/CardListFilter.js";
import CardParams from "@/domain/model/invoice/CardParams.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import ListViewUtils from "./ListViewUtils.js";

export default class ListViewModel extends RcTableViewModel {

  constructor(panel) {
    super(panel, true);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this.Strings = StringUtils;
    this.Contents = ContentUtils;
  }

  start() {
    super.start();
  }

  withToFrom(to, from) {
    super.withToFrom(to, from);
    if (to && from) {
      let toAction = to.query["action"];
      if (toAction === "detailsCard") {
        this.panel().selectedId = to.query["itemId"];
        this.panel().showDialog = "detailsCardDialog";

      } else {
        let refresh = false;

        var toQuery = Object.assign({}, to.query);
        var fromQuery = Object.assign({}, from.query);
        var action = fromQuery["action"];

        delete toQuery.action;
        delete toQuery.itemId;

        delete fromQuery.action;
        delete fromQuery.itemId;

        if (action == "addCard") {
          refresh = true;
        }

        if (JSON.stringify(toQuery, null, 0) !== JSON.stringify(fromQuery, null, 0)) {
          refresh = true;
        }

        if (StringUtils.isNotEmpty(this.panel().showDialog)) {
          this.panel().showDialog = "";
        }

        if (refresh) {
          this.reloadPage();
        }
      }
    }
    return this;
  }

  withEnterPressed(valueData) {
    super.withEnterPressed(valueData);
    return this;
  }

  withFieldValue(valueData) {
    super.withFieldValue(valueData, "card");
    return this;
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    return this;
  }

  buildRowData(tableData) {
    var utils = new ListViewUtils(this, tableData);
    utils.start();
    return this;
  }

  loadItems() {
    super.loadItems();
    var query = this.panel().$route.query;
    this.log().info("Query Params {0}", [JSON.stringify(query, null, 2)]);

    let context = {};
    context["model"] = this;
    context["query"] = query;
    context["pageName"] = "card";
    context["startTime"] = new Date();

    let filter = new CardListFilter();
    filter
      .withState(query["state"] ? query["state"] : "active")
      .withPagination(this.pageSize(query, "card"), query["page"] ? (query["page"] - 1) : 0)
      .done();

    let search = query["search"] ? query["search"] : "";
    if (StringUtils.isNotEmpty(search)) {
      filter
        .withSearchText(search)
        .done();
      filter.put("company.name", search);
    }

    context["sortBy"] = query["sortBy"] ? query["sortBy"] : "nameAz";
    filter
      .withSortBy(context["sortBy"])
      .done();

    filter
      .withCompany(this._companyId)
      .done();

    let params = new CardParams();
    params.withFilter(filter);

    var operation = this.domain().events().cards().list(params);
    operation.send(this.loadedItemsListener, context);
  }

  loadedItemsListener(notification, context) {
    var model = context.model;
    context["loadTime"] = new Date();
    if (!super.loadedItemsListener(notification, context)) {
      return;
    }

    let payload = notification.payload();
    model.processPayload(payload, context);

    delete context.model;
    context["renderTime"] = new Date();
    model.log().info("Client Performance {0}", [JSON.stringify(context, null, 2)]);
  }

  processPayload(payload, context) {
    var model = context.model;
    var query = context.query;
    var map = new CardMap(this.domain, payload?.valuesFor(CardMap.MODEL_NAME));

    model.setupData(payload, map)
    context["sortTime"] = new Date();

    model.setupPages(query, "card");

    model.showData();
  }

}